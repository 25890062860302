// Since this file is used in middleware.js. We are not keeping it 'server-only'
import {
  CSN_SEARCH_RFP_SESSION_ID_NAME,
  YEAR_COOKIE_TTL,
  CSN_SEARCH_SEARCH_ID_NAME,
  WEEK_COOKIE_TTL,
  CSN_SEARCH_SESSION_ID_COOKIE_NAME,
  CSN_SEARCH_DEVICE_ID_COOKIE_NAME,
  MONTH_COOKIE_TTL,
  AD_SESSION_ID_COOKIE
} from '@/constants/cookieConstants';
import { NextResponse } from 'next/server';
import type { NextRequest } from 'next/server';
import { v4 as uuidv4 } from 'uuid';

export function createApplicationCookies(request: NextRequest, response: NextResponse, cookieDomain: string): void {
  // Check the existence of the cookie CSNSearchRFPSessionId
  if (!request.cookies.has(CSN_SEARCH_RFP_SESSION_ID_NAME)) {
    setCookieInResponse(response, CSN_SEARCH_RFP_SESSION_ID_NAME, YEAR_COOKIE_TTL, cookieDomain);
  }

  // Check the existence of the cookie CSNSearchSearchId
  if (!request.cookies.has(CSN_SEARCH_SEARCH_ID_NAME)) {
    setCookieInResponse(response, CSN_SEARCH_SEARCH_ID_NAME, WEEK_COOKIE_TTL, cookieDomain);
  }

  // Check the existence of the cookie CSNSearchSessionId
  if (!request.cookies.has(CSN_SEARCH_SESSION_ID_COOKIE_NAME)) {
    response.cookies.set(CSN_SEARCH_SESSION_ID_COOKIE_NAME, uuidv4(), { path: '/', domain: cookieDomain });
  }

  // Check the existence of the cookie CSNSearchDeviceId
  if (!request.cookies.has(CSN_SEARCH_DEVICE_ID_COOKIE_NAME)) {
    setCookieInResponse(response, CSN_SEARCH_DEVICE_ID_COOKIE_NAME, YEAR_COOKIE_TTL, cookieDomain);
  }

  if (!request.cookies.has(AD_SESSION_ID_COOKIE)) {
    setCookieInResponse(response, AD_SESSION_ID_COOKIE, MONTH_COOKIE_TTL, cookieDomain);
  }
}

function setCookieInResponse(
  response: NextResponse,
  cookieName: string,
  cookieTTL: number,
  cookieDomain: string
): void {
  const currentTime = new Date().getTime();
  const expirationTime = new Date();
  expirationTime.setTime(currentTime + cookieTTL);
  response.cookies.set(cookieName, uuidv4(), { expires: expirationTime, path: '/', domain: cookieDomain });
}

// being called from client side on search change (search bar)
export function refreshSearchCookie(cookieDomain: string): string {
  const expirationTime = new Date();
  expirationTime.setTime(new Date().getTime() + WEEK_COOKIE_TTL);
  const expires = `expires=${expirationTime.toUTCString()}`;
  const path = 'path=/';
  const domain = `domain=${cookieDomain}`;
  const searchID = uuidv4();
  document.cookie = `${CSN_SEARCH_SEARCH_ID_NAME}=${searchID};${expires};${domain};${path}`;
  return searchID;
}

// simple helper to read cookie from browser (needed in client component where can't use cookies())
export function getCookie(name: string): string | undefined {
  if (typeof document !== 'undefined') {
    const value = `; ${document.cookie}`;
    return extractCookieValue(value, name) ?? undefined;
  }
  return undefined;
}

// extracts value for cookieName from the complete cookieHeader
export function extractCookieValue(cookieHeader: string, cookieName: string): string | null {
  try {
    const regex = new RegExp(`${cookieName}=([^;]+)`);
    const match = cookieHeader.match(regex);

    if (match) {
      return match[1];
    }
  } catch (e) {
    return null;
  }
  return null;
}
