import { useContext } from 'react';
import { LoggingContext } from '@/providers/LoggingProvider';
import type { Logger } from '@cvent/logging/types';
import { getCookie } from '@/utils/cookieHelper';
import { CSN_SEARCH_SEARCH_ID_NAME, CSN_SEARCH_SESSION_ID_COOKIE_NAME } from '@/constants/cookieConstants';

const map = new Map<string, Logger>();

/**
 * A hook to get a parent browser logger
 */
export function useLogger(name: string): Logger {
  const logger = useContext<Logger>(LoggingContext);
  const sessionId = getCookie(CSN_SEARCH_SESSION_ID_COOKIE_NAME);
  const searchId = getCookie(CSN_SEARCH_SEARCH_ID_NAME);
  if (map.has(name)) {
    return map.get(name)!;
  }
  map.set(name, logger.child({ name, sessionId, searchId }));
  return map.get(name)!;
}

export default useLogger;
