'use client';

import React, { createContext, ReactNode } from 'react';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import type { Logger } from '@cvent/logging/types';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  LoggerFactory.initConfig({
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      level: window.cventLogLevel
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...(window?.cventShouldUseDataDog
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        { datadogOptions: window.datadogOptions }
      : {
          // for development env configure a noop browser transport so that it logs to console instead of trying to send logs to DD
          browserTransport: () => null
        })
  });
}
const logger = LoggerFactory.create('venue-search-app');
export const LoggingContext = createContext<Logger>(logger);

type Props = {
  children: ReactNode;
};

export function LoggingProvider({ children }: Props) {
  return <LoggingContext.Provider value={logger}>{children}</LoggingContext.Provider>;
}

export default LoggingProvider;
