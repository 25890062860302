export const BING_COUNTRIES = ['CN']; // Country code CN: China
export const BING = 'bing';
export const GOOGLE = 'google';

export const FULL_MAP_DIMENSION = { width: 1920, height: 880 };
export const MEDIUM_MAP_DIMENSION = { width: 672, height: 667 };
export const SMALL_MAP_DIMENSION = { width: 450, height: 256 };

export const FULL_MAP_ZOOM = 13;
export const DEFAULT_ZOOM = 13;
export const DEBOUNCE_DELAY = 500;

export type MapView = 'Small' | 'Medium' | 'Full';

export const MAP_SIZE = {
  SMALL: 'Small' as MapView,
  MEDIUM: 'Medium' as MapView,
  FULL: 'Full' as MapView
};

export type ThirdPartyMapType = typeof GOOGLE | typeof BING;
