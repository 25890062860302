'use client';

import type { Dispatch, MutableRefObject, ReactNode, SetStateAction } from 'react';
import { createContext, useMemo, useRef, useState } from 'react';
import { MAP_SIZE, type MapView, type ThirdPartyMapType } from '@/constants/mapConstants';
import type { AutocompleteData } from '@/utils/autocompleteHelper';
import type { GoogleMapType } from '@/utils/maps/mapUtils';
import type { RequestCookie } from 'next/dist/compiled/@edge-runtime/cookies';
import type { BoundingBoxType, QueryParserFilterType } from '@/fetchers/getQueryParserResult';

export type MapContextType = {
  bingOrGoogle: ThirdPartyMapType;
  bingApiKey: string;
  searchBounds: BoundingBoxType | null;
  googleApiKey: string;
  toggleModal: (expanded: boolean) => void;
  venueRatesModal: boolean;
  visitedVenues: Set<string>;
  mapView: MapView;
  mapState: MapStateProps;
  polygonFilter: QueryParserFilterType | null;
  setPolygonFilter: Dispatch<SetStateAction<QueryParserFilterType | null>>;
  setMapState: Dispatch<SetStateAction<MapStateProps>>;
  setSearchBounds: Dispatch<SetStateAction<BoundingBoxType | null>>;
  setMapView: (mapView: MapView) => void;
  mapRef: MutableRefObject<GoogleMapType | null>;
  venueRatesExpanded: boolean;
  setVenueRatesExpanded: (expanded: boolean) => void;
  venueFlyoutId: string;
  setVenueFlyoutId: (id: string) => void;
};

export type AutoCompleteInput = {
  googleApiKey: string;
  bingApiKey: string;
  searchTermInputRef: React.RefObject<HTMLInputElement>;
  setTerm: (s: string) => void;
  setAutoSuggestion: (o: AutocompleteData) => void;
  locale: string;
  lat?: number;
  lon?: number;
  radius?: number;
  bingOrGoogle: ThirdPartyMapType;
};

interface Props {
  bingOrGoogle: ThirdPartyMapType;
  googleApiKey: string;
  initialMapView: MapView;
  bingApiKey: string;
  children: ReactNode;
  mapCookieValue: RequestCookie | undefined;
}

export type MapStateProps = {
  searchAsIMove: boolean;
  hoveredVenue: string | null;
  showFlyout: boolean;
  center?: { lat: number; lng: number };
  searchTerm?: string;
  // Since map change event is triggered also when we either recenter, click on pin, etc.,
  // it triggers unwanted search if `searchAsIMove` is selected. To avoid this we have added extra flag `stopChangePropagation`
  stopChangePropagation?: boolean;
  zoom?: number;
};

const initialMapState = {
  searchAsIMove: true,
  showFlyout: false,
  hoveredVenue: null
};

export const MapContext = createContext({} as MapContextType);

//  This file contains a context provider and its context. The provider is used to
//  control the state of the map on the home page, such as whether it is expanded
//  or not. The context is used to provide the mapExpanded state to any component
//  that needs it.
//  The provider is exported as default, and the context is exported as MapContext.
export default function MapProvider(props: Props) {
  const { googleApiKey, bingApiKey, initialMapView, children, bingOrGoogle, mapCookieValue } = props;
  const [venueRatesModal, toggleModal] = useState(false);
  const visitedVenuesRef = useRef<Set<string>>(new Set<string>());
  const mapRef = useRef<GoogleMapType | null>(null);
  const [searchBounds, setSearchBounds] = useState<BoundingBoxType | null>(null);
  const [mapState, setMapState] = useState<MapStateProps>(initialMapState);
  const [venueRatesExpanded, setVenueRatesExpanded] = useState<boolean>(false);
  const [venueFlyoutId, setVenueFlyoutId] = useState<string>('');
  const [polygonFilter, setPolygonFilter] = useState<QueryParserFilterType | null>(null);

  // initial map expanded or collapsed depends on map experiment
  let persistMapView = initialMapView;

  // to persist map view after first load
  if (mapCookieValue?.value === MAP_SIZE.SMALL) {
    persistMapView = MAP_SIZE.SMALL;
  } else if (mapCookieValue?.value === MAP_SIZE.MEDIUM) {
    persistMapView = MAP_SIZE.MEDIUM;
  }

  const [mapView, setMapView] = useState<MapView>(persistMapView as MapView);
  const value = useMemo(
    () => ({
      bingOrGoogle,
      bingApiKey,
      googleApiKey,
      toggleModal,
      searchBounds,
      venueRatesModal,
      mapView,
      mapState,
      setMapView,
      setMapState,
      setSearchBounds,
      visitedVenues: visitedVenuesRef.current,
      mapRef,
      venueRatesExpanded,
      setVenueRatesExpanded,
      venueFlyoutId,
      setVenueFlyoutId,
      polygonFilter,
      setPolygonFilter
    }),
    [
      bingOrGoogle,
      searchBounds,
      bingApiKey,
      googleApiKey,
      venueRatesModal,
      mapView,
      mapState,
      venueRatesExpanded,
      venueFlyoutId,
      polygonFilter
    ]
  );
  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}
