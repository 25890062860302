'use client';

import { createContext, type ReactNode, useMemo, useState, useReducer } from 'react';
import type { ShoppingCartItems } from '@/models/ShoppingCartItems';
import { ShoppingCartAction } from '@/models/ShoppingCartAction';
import { ShoppingCartItem } from '@/models/ShoppingCartItem';

export type ShoppingCartContextType = {
  items: ShoppingCartItems;
  updateCart: (action: ShoppingCartAction) => Promise<void>;
  contains: (venueId: string) => boolean;
  isFull: () => boolean;
  rfpVenueList: Array<ShoppingCartItem>;
  isRfpSentToVenue: (venueId: string) => boolean;
  highlightRecentVenue: boolean;
  setHighlightRecentVenue: (state: boolean) => void;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  isRfpListCollapsed: boolean;
  setIsRfpListCollapsed: (state: boolean) => void;
  isIncreasing: boolean | undefined;
  getVenuePositionInCart: (venueId: string) => number;
};

export const ShoppingCartContext = createContext({} as ShoppingCartContextType);

type ShoppingCartProviderProps = {
  items: ShoppingCartItems;
  maxCapacity: number;
  isLoggedIn: boolean;
  children: ReactNode;
  rfpVenueList: Array<ShoppingCartItem>;
};

// reducer to update client state using the new fancy useOptimistic hook
function cartReducer(state: ShoppingCartItems, { type, item }: ShoppingCartAction): ShoppingCartItems {
  if (type === 'add') {
    return { ...state, ...{ [item.venueId]: { ...item } } };
  }
  // delete
  const clone = { ...state };
  delete clone[item.venueId];
  return clone;
}

export default function ShoppingCartProvider({
  items,
  rfpVenueList,
  maxCapacity,
  isLoggedIn,
  children
}: ShoppingCartProviderProps) {
  const [highlightRecentVenue, setHighlightRecentVenue] = useState<boolean>(false);
  const [isRfpListCollapsed, setIsRfpListCollapsed] = useState<boolean>(true);
  const [localItems, setCartItems] = useState<ShoppingCartItems>(items);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isIncreasing, setIsIncreasing] = useState<boolean>();
  const [cartItems, updateCart] = useReducer(cartReducer, localItems);
  const value = useMemo(() => {
    const venuesInCart = Object.keys(cartItems);
    const { length } = venuesInCart;
    const cart = {
      items: Object.freeze(cartItems),
      updateCart: async (action: ShoppingCartAction) => {
        const shoppingCartAction = { ...action, shoppingCartItems: cartItems };
        setIsIncreasing(action.type === 'add');
        updateCart(shoppingCartAction);
        // do slow thing
        const result = await fetch('/venues/api/cart', {
          method: 'POST',
          body: JSON.stringify(shoppingCartAction)
        });
        // make result real
        if (result.ok) {
          setCartItems(await result.json());
          if (shoppingCartAction.type === 'add' && !highlightRecentVenue) {
            if (!isLoggedIn || (isLoggedIn && length === 0)) {
              setHighlightRecentVenue(true);
              if (!isOpen) setIsOpen(true);
            }
          }
        }
        // state reverts back we get here and result was not ok.
      },
      contains: (venueId: string) => cartItems[venueId] !== undefined,
      isFull: () => length >= maxCapacity,
      rfpVenueList,
      isRfpSentToVenue: (venueId: string) => rfpVenueList.find(rfpVenue => venueId === rfpVenue.venueId) === undefined,
      highlightRecentVenue,
      setHighlightRecentVenue,
      isOpen,
      setIsOpen,
      isRfpListCollapsed,
      setIsRfpListCollapsed,
      isIncreasing,
      getVenuePositionInCart: (venueId: string) => venuesInCart.indexOf(venueId)
    };
    return cart;
  }, [
    cartItems,
    rfpVenueList,
    highlightRecentVenue,
    isOpen,
    isRfpListCollapsed,
    isIncreasing,
    updateCart,
    isLoggedIn,
    maxCapacity
  ]);
  return <ShoppingCartContext.Provider value={value}>{children}</ShoppingCartContext.Provider>;
}
