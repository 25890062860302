export const CVENT_AUTH_TOKEN = 'csn-appointment-auth';
export const CVENT_NOTIFICATION_COOKIE_NAME = 'cvent_notifications';
export const AI_MODE_COOKIE_NAME = 'csn-ai-search';
export const COUNTRY_OVERRIDE_COOKIE = 'sriracha-country-override';
export const CSN_SEARCH_SESSION_ID_COOKIE_NAME = 'CSNSearchSessionId';
export const CSN_SEARCH_SEARCH_ID_NAME = 'CSNSearchSearchId';
export const CSN_SEARCH_RFP_SESSION_ID_NAME = 'CSNSearchRFPSessionId';
export const CSN_SEARCH_DEVICE_ID_COOKIE_NAME = 'CSNSearchDeviceId';
export const CVENT_COOKIE_BANNER_ACCEPT = 'cvt_cookieconsent-';
export const CSN_SEARCH_V2_ACTIVE = 'csn-search-v2-active';
export const CSN_SEARCH_BANNER_COOKIE_NAME = 'csn_search_info_banner';
export const CSN_MAP_VIEW_COOKIE = 'csn_map_view';

// sessionId needed to track the suggestedAds
export const AD_SESSION_ID_COOKIE = '_adssid';

export const BASE_TTL_DAY = 24 * 60 * 60 * 1000;
export const WEEK_COOKIE_TTL = 7 * BASE_TTL_DAY;

export const MONTH_COOKIE_TTL = 30 * BASE_TTL_DAY;
export const YEAR_COOKIE_TTL = 365 * BASE_TTL_DAY;
export const DECADE_COOKIE_TTL = 3650 * BASE_TTL_DAY;
