import debounce from 'lodash/debounce';
import type { BaseFactDetails } from '@/analytics/models/cventAnalytics/BaseFact';
import { CSN_SEARCH_DEVICE_ID_COOKIE_NAME } from '@/constants/cookieConstants';
import { getCookie } from '@/utils/cookieHelper';
import { getPageUrl } from '@/utils/analyticsClientHelper';
import getFetchClient from '@/fetchers/getFetchClientBrowser';
import type { Logger } from '@cvent/logging/types';

type Reporter = (facts) => Promise<void> | void;
interface Config {
  analyticsReporter?: Reporter;
}

// Mock analytics reporter
export function mockAnalyticsReporter() {
  return (facts): void => {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(facts));
  };
}

export class FactStore {
  pendingFacts;

  constructor() {
    this.pendingFacts = [];
  }

  /* eslint-disable @typescript-eslint/explicit-member-accessibility */
  add(fact) {
    const isArray = Array.isArray(fact);
    if (isArray && !fact.length) {
      return;
    }

    if (isArray && fact.length) {
      fact.forEach(item => {
        this.pendingFacts.push(item);
      });
    } else {
      this.pendingFacts.push(fact);
    }
  }

  clear() {
    this.pendingFacts.length = 0;
  }

  get() {
    return this.pendingFacts;
  }
}

function getTooManyFactsFact(facts) {
  const countMap = {};
  facts.forEach(fact => {
    if (Object.prototype.hasOwnProperty.call(countMap, fact.type)) {
      countMap[fact.type]++;
    } else {
      countMap[fact.type] = 1;
    }
  });
  return {
    type: 'analytics/METRICS_THRESHOLD_VIOLATED',
    location: getPageUrl() ?? '',
    deviceId: getCookie(CSN_SEARCH_DEVICE_ID_COOKIE_NAME) ?? '',
    ts: new Date().getTime(),
    ...countMap
  };
}

export function reportPendingFacts(factStore: FactStore, config: Config) {
  const {
    analyticsReporter = mockAnalyticsReporter() // default client is to a mock backend.
  } = config;
  const maxWaitMs = 5000;
  const maxFactsPerInterval = 200;
  return debounce(
    () => {
      const pendingFacts = factStore.get();
      if (maxFactsPerInterval <= pendingFacts.length) {
        // if we blew our max, report fact with counts by fact type
        analyticsReporter([getTooManyFactsFact(pendingFacts)]);
      } else {
        analyticsReporter(pendingFacts);
      }
      factStore.clear();
    },
    maxWaitMs,
    { maxWait: maxWaitMs, leading: true }
  );
}

export function cventAnalyticsReporter(url: string, logger: Logger) {
  const fetchClient = getFetchClient();
  return async facts => {
    try {
      const response = await fetchClient.post(url, JSON.stringify(facts), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response?.status !== 200 && response?.status !== 201 && response?.status !== 204) {
        throw Error('Failed to push logs from reporter.ts');
      }
    } catch (error) {
      logger.error('Failed to push logs from reporter.ts. Error: %o', error);
    }
  };
}

export const cventAnalytics = (analyticsReporter: Reporter, factStore: FactStore) => {
  const reportPendingFactsCallback = reportPendingFacts(factStore, {
    analyticsReporter
  });
  return {
    track: (fact, baseProperties: BaseFactDetails | null, timestamp: number): void => {
      const finalFact = baseProperties ? { ...baseProperties, ...fact, ts: timestamp } : { ...fact, ts: timestamp };
      factStore.add(finalFact);
      reportPendingFactsCallback();
    },
    trackNow: async (fact, baseProperties: BaseFactDetails, timestamp: number): Promise<void> => {
      const finalFact = { ...baseProperties, ...fact, ts: timestamp };
      factStore.add(finalFact);
      await analyticsReporter(factStore.get());
      factStore.clear();
    }
  };
};
