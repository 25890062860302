'use client';

import React, { createContext, useState, useEffect, ReactNode } from 'react';
import Analytics from '@/analytics/Analytics';
import type { BaseFactDetails } from '@/analytics/models/cventAnalytics/BaseFact';
import useLogger from '@/hooks/useLogger';

const mockAnalytics: Analytics = {
  baseProperties: {} as BaseFactDetails,
  reportFact: () => {},
  reportFactImmediately: () => Promise.resolve(),
  updateBaseProperties: () => {},
  updateSearchId: () => {},

  cventAnalytics: {
    track: (_fact, _baseProperties: BaseFactDetails, _timestamp: number) => {},
    trackNow: (_fact, _baseProperties: BaseFactDetails, _timestamp: number) => Promise.resolve()
  },
  url: undefined
};

export const AnalyticsContext = createContext(mockAnalytics);

type Props = {
  children: ReactNode;
  baseProperties: BaseFactDetails;
  analyticsServiceUrl: string | undefined;
};

/**
 * Provides a context for tracking analytics.
 */
export function AnalyticsProvider({ children, baseProperties, analyticsServiceUrl }: Props) {
  const [analytics, setAnalytics] = useState<Analytics>(mockAnalytics);
  const logger = useLogger('AnalyticsProvider.tsx');

  useEffect(() => {
    setAnalytics(new Analytics(analyticsServiceUrl, baseProperties, logger));
    // Initialize this only once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    analytics.updateBaseProperties(baseProperties);
  }, [analytics, baseProperties]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}
