import { useContext } from 'react';
import { BreakPointContext } from '@/providers/BreakPointProvider';
import type { BreakPointContextType } from '@/providers/BreakPointProvider';
/**
 * A hook for getting access to active breakpoints in client components
 * TEMP, please delete after carina v2!
 */
export function useBreakpoints(): BreakPointContextType {
  const breakpoints = useContext<BreakPointContextType>(BreakPointContext);
  return breakpoints;
}
