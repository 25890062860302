export const baseLocales = [
  'en-US',
  'de-DE',
  'es-ES',
  'fr-FR',
  'fr-CA',
  'it-IT',
  'nl-NL',
  'pt-BR',
  'pt-PT',
  'fi-FI',
  'sv-SE',
  'tr-TR',
  'ja-JP',
  'ko-KR',
  'ru-RU',
  'th-TH'
] as const;

export const legacyChineseLocales = ['zh-CHT', 'zh-CN'] as const;
const modernChineseLocales = ['zh-Hant', 'zh-Hans'] as const;

const languages = ['en', 'de', 'es', 'fr', 'it', 'pt', 'fi', 'sv', 'tr', 'ja', 'ko', 'ru', 'th', 'zh'] as const;

export type Language = (typeof languages)[number];

const languageSet = new Set(languages);

const languageCodeToLocaleMap: Record<Language, Locale> = {
  en: 'en-US',
  de: 'de-DE',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  pt: 'pt-PT',
  fi: 'fi-FI',
  sv: 'sv-SE',
  tr: 'tr-TR',
  ja: 'ja-JP',
  ko: 'ko-KR',
  ru: 'ru-RU',
  th: 'th-TH',
  zh: 'zh-CN'
};

type BaseLocale = (typeof baseLocales)[number];
type LegacyChineseLocale = (typeof legacyChineseLocales)[number];
type ModernChineseLocale = (typeof modernChineseLocales)[number];

/**
 *  Internal locales used for formatting and translation via phrase app data.
 */
export type PhraseLocale = BaseLocale | ModernChineseLocale;

/**
 *  Locales visible to the user via the locale switcher and in URL paths
 */
export type Locale = BaseLocale | LegacyChineseLocale;

export const supportedLanguages: Array<{ name: string; code: Locale }> = [
  { name: 'Deutsch', code: 'de-DE' },
  { name: 'English', code: 'en-US' },
  { name: 'Español', code: 'es-ES' },
  { name: 'Français', code: 'fr-FR' },
  { name: 'Français (Canada)', code: 'fr-CA' },
  { name: 'Italiano', code: 'it-IT' },
  { name: 'Nederlands', code: 'nl-NL' },
  { name: 'Português (Brasil)', code: 'pt-BR' },
  { name: 'Português (europeu)', code: 'pt-PT' },
  { name: 'Suomi', code: 'fi-FI' },
  { name: 'Svenska', code: 'sv-SE' },
  { name: 'Türkçe', code: 'tr-TR' },
  { name: '中文（简体）', code: 'zh-CN' },
  { name: '中文（繁體）', code: 'zh-CHT' },
  { name: '日本語', code: 'ja-JP' },
  { name: '한국어', code: 'ko-KR' },
  { name: 'Русский', code: 'ru-RU' },
  { name: 'ไทย', code: 'th-TH' }
];

export enum supportedLocales {
  EN_US = 'en-US',
  DE_DE = 'de-DE',
  ES_ES = 'es-ES',
  FR_FR = 'fr-FR',
  FR_CA = 'fr-CA',
  IT_IT = 'it-IT',
  NL_NL = 'nl-NL',
  PT_BR = 'pt-BR',
  PT_PT = 'pt-PT',
  FI_FI = 'fi-FI',
  SV_SE = 'sv-SE',
  TR_TR = 'tr-TR',
  ZH_CN = 'zh-CN',
  ZH_CHT = 'zh-CHT',
  JA_JP = 'ja-JP',
  KO_KR = 'ko-KR',
  RU_RU = 'ru-RU',
  TH_TH = 'th-TH'
}

export const i18n = {
  locales: supportedLanguages.map(obj => obj.code),
  defaultLocale: 'en-US'
} as const;

export const LOCALE_HEADER = 'x-cvent-locale';

export const localeSet = new Set(i18n.locales);

/**
 * Take the locale value used in SEO and shown to the user and convert it to the locale used in the app for formatting and translation
 * @param plannerLocale
 * @returns a locale that we support
 */
export function toPhraseLocale(plannerLocale: Locale): PhraseLocale {
  if (plannerLocale === 'zh-CN') {
    return 'zh-Hans';
  }
  if (plannerLocale === 'zh-CHT') {
    return 'zh-Hant';
  }
  return plannerLocale;
}

/**
 *  Attempt to match a PlannerLocale we support and fallback in various ways
 */
export function resolvePlannerLocale(potentialLocale = 'en-US'): Locale {
  if (potentialLocale === 'en-US') {
    return 'en-US';
  }
  let locale = potentialLocale;

  // Bring it to standard format
  const plannerLocaleArr = potentialLocale.split('-');
  if (plannerLocaleArr.length === 2) {
    locale = `${plannerLocaleArr[0]}-${plannerLocaleArr[1].toUpperCase()}`;
  }

  if (localeSet.has(locale as Locale)) {
    return locale as Locale;
  }

  const supportedLocale = convertToSupportedChineseLocale(locale);
  if (supportedLocale) {
    return supportedLocale as Locale;
  }

  // no language code found
  if (!languageSet.has(plannerLocaleArr[0] as Language)) {
    return i18n.defaultLocale;
  }
  // return default for just a language code
  return languageCodeToLocaleMap[plannerLocaleArr[0] as Language];
}

// get date-fns supported locale from our locale
export function getDateFnsLocaleCode(locale: Locale): string {
  switch (locale) {
    case 'de-DE':
    case 'es-ES':
    case 'fi-FI':
    case 'fr-FR':
    case 'it-IT':
    case 'ja-JP':
    case 'ko-KR':
    case 'nl-NL':
    case 'pt-PT':
    case 'ru-RU':
    case 'sv-SE':
    case 'th-TH':
    case 'tr-TR':
      return locale?.substring(0, 2);
    case 'fr-CA':
      return 'fr-CA';
    case 'pt-BR':
      return 'pt-BR';
    // Some approximation here; Hong Kong/Taiwan has traditionally used Traditional more than Simplified, but these locales don't exist in date-fns
    // This behaviour maps to mobile standards and its behavior has been aligned with the Localization team
    case 'zh-CN':
      return 'zh-CN';
    case 'zh-CHT':
      return 'zh-TW';
    default:
      return 'en-US';
  }
}

// Is it one of unsupported chinese locales
export function convertToSupportedChineseLocale(locale: string): string | undefined {
  switch (locale) {
    case 'zh-CHS':
    case 'zh-SG':
      return 'zh-CN';
    case 'zh-HK':
    case 'zh-MO':
    case 'zh-TW':
      return 'zh-CHT';
    default:
      return undefined;
  }
}
