import type { BaseFactDetails } from '@/analytics/models/cventAnalytics/BaseFact';
import { cventAnalytics, cventAnalyticsReporter, FactStore, mockAnalyticsReporter } from '@/analytics/reporter';
import type { Logger } from '@cvent/logging/types';

/**
 *  Placeholder interface for the reportFact function until full worker + react context implementation is finished
 *  and wired up to the app.  Most likely will change a little and will not come from lib.
 *  TODO: start creating a fact types based on the requirements / the old facts but using typescript types as per below.
 */

/**
 *  A typescript type to enforce a json serializable type
 */
type AsJson<T> = T extends string | number | boolean | null
  ? T
  : T extends typeof Function
  ? never
  : T extends object
  ? { [K in keyof T]: AsJson<T[K]> }
  : never;

/**
 *  Base Cvent Analytics Fact's have type identifier + a millisecond timestamp (epoch based)
 */
export type BaseFact = {
  type: string;
  ts: number;
};

/**
 *  A way to allow for Json only values + BaseFact to be a typescript type via generics
 *  based on https://stash.cvent.net/projects/ANL/repos/analytics/browse/analytics-shared/src/main/java/com/cvent/analytics/model/Fact.java
 */
export type Fact<T> = T & AsJson<T> & BaseFact;

export default class Analytics {
  public baseProperties: BaseFactDetails;

  public url: string | undefined;

  public cventAnalytics: {
    trackNow: (fact, baseProperties: BaseFactDetails, timestamp: number) => Promise<void>;
    track: (fact, baseProperties: BaseFactDetails | null, timestamp: number) => void;
  };

  constructor(url: string | undefined, baseProperties: BaseFactDetails, logger: Logger) {
    /**
     * Analytics service URL
     */
    this.url = url;

    /**
     * Properties that will get sent with every fact
     */
    this.baseProperties = baseProperties;

    const analyticsReporter = url ? cventAnalyticsReporter(url, logger) : mockAnalyticsReporter();
    const factStore = new FactStore();
    this.cventAnalytics = cventAnalytics(analyticsReporter, factStore);
  }

  updateBaseProperties(properties: Partial<BaseFactDetails>) {
    this.baseProperties = {
      ...this.baseProperties,
      ...properties
    };
  }

  updateSearchId({ searchId }): void {
    this.updateBaseProperties({ search_id: searchId });
  }

  /**
   * Publishes a fact to the destinations defined in the fact definition
   */
  reportFact<T>(fact: Omit<Fact<T>, 'ts'>, ts?: number | null, includeBaseProperties = true): void {
    this.cventAnalytics.track(fact, includeBaseProperties ? this.baseProperties : null, ts ?? Date.now());
  }

  /**
   * Publishes a fact to the destinations defined in the fact definition without any delay
   */
  async reportFactImmediately<T>(fact: Omit<Fact<T>, 'ts'>, ts?: number): Promise<void> {
    await this.cventAnalytics.trackNow(fact, this.baseProperties, ts ?? Date.now());
  }
}
