'use client';

import { createContext, useEffect, useState, useCallback, useMemo } from 'react';
import type { ReactNode } from 'react';

// TODO:  this is temporary and should be deleted once carina v2 is in place.
// This is temporary and intended to optimize a bad hook already in place in this code base
// TODO: remove this class!!!!

// not bundling this for 2 numbers..... for a temp class.
// import carinaTheme from '@cvent/carina-dsm/tailwind-theme-light';

const CARINA_MD = 768; // parseInt(carinaTheme.screens.md, 10);
const CARINA_LG = 1024; // parseInt(carinaTheme.screens.lg, 10);;
export const CARINA_XL = 1280; // parseInt(carinaTheme.screens.xl, 10);;

export type BreakPointContextType = {
  screenWidth: number | undefined;
  isMobile: boolean;
  isTablet: boolean;
  isSmallDesktop: boolean;
  isDesktop: boolean;
};

export const BreakPointContext = createContext({} as BreakPointContextType);

export default function BreakPointProvider(props: { children: ReactNode }) {
  const { children } = props;

  const [width, setWidth] = useState<number | undefined>(undefined);

  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setWidth(window.innerWidth);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  const value = useMemo(
    () => ({
      screenWidth: width,
      isMobile: width !== undefined && width <= CARINA_MD,
      isTablet: width !== undefined && width > CARINA_MD && width <= CARINA_LG,
      isSmallDesktop: width !== undefined && width > CARINA_LG && width <= CARINA_XL,
      isDesktop: width !== undefined && width > CARINA_XL
    }),
    [width]
  );

  return <BreakPointContext.Provider value={value}>{children}</BreakPointContext.Provider>;
}
